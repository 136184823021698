<template>
  <v-container fluid class="py-0 px-0 view-container" id="printPage">
    <report-header id="ready_to_know" class="topMarginNegative80">
      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col class="d-flex justify-end pr-sm-10 pr-2">
            <!-- <v-btn elevation="2" @click="onPrint">Imprimir</v-btn> -->
            <!-- <o-tooltip value="Test">
              <v-btn>Test</v-btn>
            </o-tooltip> -->
          </v-col>
        </v-row>
      </template>
    </report-header>

    <leader-emotion-graph :data="data" />
    <leader-mind-graph :data="data" />
    <leader-behavior-graph :data="data" />
    <leader-general-graph :data="data" />
  </v-container>
</template>

<script lang="js">
import LeaderBehaviorGraph from "./components/LeaderBehaviorGraph.vue";
import LeaderEmotionGraph from "./components/LeaderEmotionGraph.vue";
import LeaderGeneralGraph from "./components/LeaderGeneralGraph.vue";
import LeaderMindGraph from "./components/LeaderMindGraph.vue";
import ReportHeader from "../common/ReportHeader.vue";

export default {
  name: "TeamLeaderReport",
  components: {
    "report-header": ReportHeader,
    "leader-emotion-graph": LeaderEmotionGraph,
    "leader-mind-graph": LeaderMindGraph,
    "leader-behavior-graph": LeaderBehaviorGraph,
    "leader-general-graph": LeaderGeneralGraph
  },
  data() {
    return {
      data: []
    };
  },
  methods: {
    getReportData() {
      const reportIds = this.$route.query.id;
      if (reportIds && reportIds.length > 0) {
        this.data = [];
        reportIds.forEach(id => {
          this.$store
            .dispatch("getReportData", id)
            .then(response => {
              this.data.push(response);
            })
            .catch(error => {
              console.log(error);
            });
        });
      }
    },
    onPrint() {
      window.print();
    }
  },
  mounted() {
    this.getReportData();
  }
};
</script>

<style scoped>
@media print {
  @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
  .topMarginNegative80 {
    margin-top: -80px;
  }
  html {
    overflow-y: auto;
  }
  body {
    width: 12in;
    height: 18in;
    padding-top: 5px;
  }
}
</style>
