<template>
  <assessment-back-next-card-layout
    title="Seleccione la conducta que mejor te describa"
    :step="step"
    :steps="steps"
    @next="next"
    @back="back"
  >
    <v-form lazy-validation>
      <v-row justify="center">
        <v-col cols="12">
          <h2 class="mb-2">{{ currentSection.label }}</h2>
          <v-row>
            <v-col
              cols="12"
              v-for="(question, questionIndex) in currentSection.items"
              :key="`question-${questionIndex}`"
              subtitle-1
              class="text-center pb-0"
            >
              <p>{{ question }}</p>
              <v-row class="justify-center align-center">
                <v-col cols="2" class="pt-0 d-none d-sm-flex justify-end">
                  Nunca
                </v-col>

                <v-col cols="auto" class="pt-0">
                  <v-radio-group
                    v-model="answers[currentSection.key][questionIndex]"
                    row
                  >
                    <v-radio
                      v-for="value in 5"
                      :key="`answer-${questionIndex}-${value}`"
                      :label="`${value}`"
                      :value="value"
                      class="mr-2"
                    >
                      <template v-slot:label>
                        <span>{{ value }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="2" class="pt-0 d-none d-sm-flex">Siempre</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center py-0">
        <v-col cols="12" v-if="error" class="red--text text-center py-0">
          {{ error }}
        </v-col>
      </v-row>
    </v-form>
  </assessment-back-next-card-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";

import assessmentMixin from "@/mixins/assessmentMixin";
import questions from "@/model/self-leadership/questions.js"

export default defineComponent({
  name: "SelfLeadQuestions",
  mixins: [assessmentMixin],
  data() {
    return {
      answers: null,
      error: false,
      sections: null,
    };
  },
  created() {
    if (this.getAndValidateAssessment()) {
      this.sections = questions

      if (this.$store.getters.answers) {
        this.answers = this.$store.getters.answers;
    } else {
        this.answers = this.sections.reduce(
          (answers, { key, items }) => ({
            ...answers,
            [key]: new Array(items.length).fill(undefined)
          }),
          {}
        );
      }
    }
  },
  computed: {
    step() {
      return this.$store.getters.currentStep;
    },
    steps() {
      return this.sections.length;
    },
    currentSection() {
      return this.sections[this.step];
    },
  },
  methods: {
    back() {
      this.error = false;

      if (this.step == 0) {
        this.setAnswers();
        this.$store.dispatch("setAnswers", this.answers);
        this.$router.push(this.assessmentFormURL);
      } else {
        this.$store.dispatch("back");
      }
    },
    isValidSelection() {
      return !!this.answers[this.currentSection.key]
        && this.answers[this.currentSection.key].every(item => !!item);
    },
    next() {
      if (this.isValidSelection()) {
        if (this.step === this.steps - 1) {
          this.setAnswers();
          this.$router.push(this.assessmentSubmitURL);
        } else {
          this.$store.dispatch("next");
        }
      } else {
        this.error = "Debe seleccionar todas las opciones para continuar";
      }
    },
    setAnswers() {
  this.$store.dispatch("setAnswers", this.answers);
  }
},
});
</script>

<style scoped>
p {
  font-size: 1rem;
  font-weight: 500;
}
</style>
